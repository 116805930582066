import * as React from 'react'

import './../styles/reset.css'
import './../styles/style.scss'

export const Layout = ({ isHomepage, children, navigation }) => (
  <>
    {children}
  </>
)
