
import * as React from 'react'
import { graphql } from 'gatsby'

import { Seo } from '../components/Seo'
import { MainContent } from '../components/MainContent'
import { Layout } from '../components/Layout'


const HomeTemplate = ({ data }) => {
  if (!data) return null
  // const doc = data.prismicHomepage.data

  return (
    <Layout>
      <Seo title="Rework Entertainment" />
      <MainContent />
    </Layout>
  )
}

export const query = graphql`
  query Homepage {
    prismicHomepage {
      data {
        banner_title {
          text
        }
        banner_description {
          text
        }
        banner_link {
          url
          type
          uid
        }
        banner_link_label {
          text
        }
        banner_background {
          url
        }
      }
    }
  }
`

export default HomeTemplate
