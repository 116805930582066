import logo from '../images/rework_logo.png'
import bg from '../images/bg.jpg'
import mountains from '../images/mountains.png'
import mountainsSmall from '../images/mountains_small.png'
import team01 from '../images/team/team_01.jpg'
import team02 from '../images/team/team_02.png'
import team03 from '../images/team/team_03.png'
import pattern from '../images/Pattern.png'
import project01 from '../images/projects/project_01.jpg'
import project02 from '../images/projects/project_02.png'
import project03 from '../images/projects/project_03.png'
import socialTwitter from '../images/icons/Twitter.svg'
import socialLinkedin from '../images/icons/Linkedin.svg'
import socialYoutube from '../images/icons/YouTube.svg'
import socialPhone from '../images/icons/Phone.svg'
import socialMail from '../images/icons/Mail.svg'
import socialMouse from '../images/icons/Mouse.svg'
import brandCetelem from '../images/brands/Logo_Cetelem.png'
import brandMasterClash from '../images/brands/Logo_MastersClash.png'
import brandMeta from '../images/brands/Logo_Meta.png'
import brandXp from '../images/brands/Logo_Xp.png'
import brandTf1 from '../images/brands/Logo_TF1.png'
import brandValEurope from '../images/brands/Logo_ValEurope.png'
import brandWebedia from '../images/brands/Logo_Webedia.png'
import { Footer } from './Footer'
import React from "react"
import ReCAPTCHA from "react-google-recaptcha";

export function MainContent() {
  const recaptchaRef = React.createRef();

  const searchParam = (param) => {
    const isBrowser = () => typeof window !== "undefined"
    if (!isBrowser()) {
      return false
    }
    var url = new URLSearchParams(window.location.search);
    return url.has(param)
  }

  const onSubmitWithReCAPTCHA = async (e) => {
    e.preventDefault()
    const token = await recaptchaRef.current.executeAsync();

    let contactForm = document.getElementById('contact-form');
    let formData = new FormData(contactForm)

    formData.set('g-recaptcha-response', token)
    contactForm.submit()
  }

  return (
    <main className="container">
      { searchParam("form-error") &&
      <div className="form-pop form-error">Une erreur s'est produite lors de l'envoi de votre message, veuillez réessayer.</div>
      }
      { searchParam("form-success") &&
      <div className="form-pop form-success">Votre message a bien été envoyé.</div>
      }
      <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      <section className="banner"
      >
      <div className="banner-wrapper">
        <img alt="logo" className="banner-logo" src={logo}></img>
        <img alt="mountains drawing"className="banner-mountains" src={mountains}></img>
        <div className="mountains-bg"></div>
      </div>
      <div className='banner-bg'style={{
        backgroundImage: `url(${bg})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: '810px',
        opacity: '0.8',
        position: 'absolute',
        left: '0',
        top: '0'
      }} ></div>
      </section>

      <div className='content-wrapper'>



      <section className="work">
          <div className="titleGroup">
            <h2>NOS RÉALISATIONS</h2>
          </div>
          <div className="work-cards">
            <div className="work-card" style={{backgroundImage: `url(${project01})`}}>
              <div className="blur"></div>
              <div className="grad-bg"></div>
              <div className="pattern-bg" style={{backgroundImage: `url(${pattern})`}}></div>
              <div className="info-wrapper">
                <div className='name'>MENTORS</div>
                <div className='genre'>Série Documentaire</div>
                {/* <div className='client'>Blizzard Entertainment</div> */}
                <div className='format'>6x10'</div>
                <div className='platforms'>Youtube, lequipe.fr</div>
                <a href="https://drive.google.com/file/d/12ygYlxs1cqkWi17fYum1_2N76Ovzrqtv/view?usp=share_link"><div className='watch'>Regarder</div></a>
              </div>
            </div>
            <div className="work-card" style={{backgroundImage: `url(${project02})`}}>
              <div className="blur"></div>
              <div className="grad-bg"></div>
              <div className="pattern-bg" style={{backgroundImage: `url(${pattern})`}}></div>
              <div className="info-wrapper">
                <div className='name'>Startup PAYFOOT</div>
                <div className='genre'>Vidéo de présentation B2B</div>
                {/* <div className='client'>France Télévision</div> */}
                <div className='format'>1' </div>
                <br></br>
                <div className='platforms'>B2B, site web</div>
                <a href="https://drive.google.com/file/d/12ygYlxs1cqkWi17fYum1_2N76Ovzrqtv/view?usp=sharing"><div className='watch'>Regarder</div></a>
              </div>
            </div>
            <div className="work-card" style={{backgroundImage: `url(${project03})`}}>
              <div className="blur"></div>
              <div className="grad-bg"></div>
              <div className="pattern-bg" style={{backgroundImage: `url(${pattern})`}}></div>
              <div className="info-wrapper">
                <div className='name'>Aftermovie Rocket Show</div>
                <div className='genre'>Aftermovie</div>
                {/* <div className='client'>Pop-Corn</div> */}
                <div className='format'>2'</div>
                <div className='platforms'>Réseaux sociaux</div>
                <a href="https://drive.google.com/file/d/1WwjuemPgDFaNNIRQqpokPmiKiZ0QdcuH/view?usp=share_link"><div className='watch'>Regarder</div></a>
              </div>
            </div>
          </div>
        </section>

        <div className="objectives">
            <h2>Notre expertise</h2>
            <p>Raconter des histoires originales et authentiques sur vous et vos valeurs.</p>
        </div>

        <section className="services">
          <h2>NOS SERVICES</h2>
          <div className="services-text">
          Que vous souhaitiez raconter une histoire à travers un documentaire ou faire revivre à votre public un événement que vous avez organisé via un aftermovie, REWORK ENTERTAINEMENT a la solution adaptée à tous vos besoins, avec une livraison clé en main de votre projet.
          </div>
        </section>

        <section className="formats">
            <div className="format long-formats">
              <div className="format-bg" style={{backgroundImage: `url(${mountainsSmall})`,}}></div>
              <h3>Formats moyens et longs</h3>
              <ul>
                <li>Documentaire unitaire</li>
                <li>Série documentaire</li>
                <li>Reportage</li>
                <li>Émission</li>
              </ul>
            </div>
            <div className="format short-formats">
            <h3>Formats courts</h3>
              <ul>
                <li>After-movie</li>
                <li>Présentation, mise en avant de votre entreprise</li>
                <li>Vidéo dédiée aux réseaux sociaux</li>
                <li>Bande-annonce</li>
              </ul>
              <div className="format-bg" style={{backgroundImage: `url(${mountainsSmall})`,}}></div>
            </div>
        </section>

        <section className="about">
          <h2>NOUS SOMMES</h2>
          <div className="text">
          <p>
          Rework Entertainment est une société de production audiovisuelle spécialisée dans les contenus institutionnels et les documentaires.
          Fondé par d'anciens du monde de la télévision, Rework est née en 2021 avec comme objectif de réaliser des productions humaines et inspirantes.
          </p>
          </div>
        </section>

        <section className="team">
          <div className="team-cards">
          <div className="team-card" style={{backgroundImage: `url(${team01})`}}>
              <div className="blur"></div>
              <div className="grad-bg"></div>
              <div className="pattern-bg" style={{backgroundImage: `url(${pattern})`}}></div>
              <div className="info-wrapper">
                <div className='name'>Jules Thiébaut</div>
                <div className='position'>Animateur / Producteur</div>
                <div className='description'>Animateur, producteur et spécialiste des contenus digitaux, il a travaillé pour le groupe Webedia et sur de nombreuses productions du groupe M6.</div>
                <ul className='socials'>
                  <a href="mailto:jules.thiebaut@reworkentertainment.com"><li className='social mail'><img alt="social logo" className="social-logo" src={socialMail}></img></li></a>
                  <a href="https://www.linkedin.com/in/jules-thiébaut-b7729b102"><li className='social linkedin'><img alt="social logo" className="social-logo" src={socialLinkedin}></img></li></a>
                </ul>
              </div>
            </div>
            <div className="team-card" style={{backgroundImage: `url(${team02})`}}>
              <div className="blur"></div>
              <div className="grad-bg"></div>
              <div className="pattern-bg" style={{backgroundImage: `url(${pattern})`}}></div>
              <div className="info-wrapper">
                <div className='name'>Erwan Lancelot</div>
                <div className='position'>Directeur Éditorial</div>
                <div className='description'>Journaliste de formation, spécialiste en communication et en Esports, il a travaillé pour plusieurs médias nationaux avant de rejoindre le groupe Webedia.</div>
                <ul className='socials'>
                  <a href="mailto:erwan.lancelot@reworkentertainment.com"><li className='social mail'><img alt="social logo" className="social-logo" src={socialMail}></img></li></a>
                  <a href="https://www.linkedin.com/in/erwanlancelot"><li className='social linkedin'><img alt="social logo" className="social-logo" src={socialLinkedin}></img></li></a>
                </ul>
              </div>
            </div>
            <div className="team-card" style={{backgroundImage: `url(${team03})`}}>
              <div className="blur"></div>
              <div className="grad-bg"></div>
              <div className="pattern-bg" style={{backgroundImage: `url(${pattern})`}}></div>
              <div className="info-wrapper">
                <div className='name'>Romain Rumak</div>
                <div className='position'>Directeur des productions</div>
                <div className='description description-smaller'>Producteur d’émissions TV et de documentaires, mais aussi spécialiste en diffusion TV et distribution de droits, il a travaillé pour les groupes Lagardère et Webedia ainsi que pour la chaîne Public Sénat.</div>
                <ul className='socials'>
                  <a href="mailto:romain.rumak@reworkentertainment.com"><li className='social mail'><img alt="social logo" className="social-logo" src={socialMail}></img></li></a>
                  <a href="https://www.linkedin.com/in/romain-rumak-8432a589"><li className='social linkedin'><img alt="social logo" className="social-logo" src={socialLinkedin}></img></li></a>
                </ul>
              </div>
            </div>
          </div>

        </section>

        <section className="clients">
          <h3>Ils nous font confiance</h3>
          <div className="clients-logos">
            <img alt="client logo" className="client-logo" src={brandMasterClash}></img>
            <img alt="client logo" className="client-logo" src={brandValEurope}></img>
            <img alt="client logo" className="client-logo" src={brandWebedia}></img>
            <img alt="client logo" className="client-logo" src={brandCetelem}></img>
            <img alt="client logo" className="client-logo" src={brandTf1}></img>
            <img alt="client logo" className="client-logo" src={brandMeta}></img>
            <img alt="client logo" className="client-logo" src={brandXp}></img>
          </div>
        </section>

      </div>


        <div className="content-wrapper">
        <section className="contact-form">
          <div className="titleGroup">
            <h2>Envie d'en discuter ?</h2>
          </div>
          <div className="formBox">
            <form id="contact-form" method="POST" action="https://reworkentertainment.com/mailer/mailer.php" onSubmit={onSubmitWithReCAPTCHA}>
              <input type="email" required className="input" id="email" name="email" placeholder='Votre email'/><br/>
              <textarea type="textarea" required className="input" id="message" name="message" placeholder='Votre message' /><br/>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LfFmWYfAAAAABjv5N9yc9JZgdokxTP2ziTEOgg-"
                theme="dark"
                size="invisible"
              />
              <button type="submit" className="submit">Envoyer</button>
            </form>
            <div className='formBg'style={{backgroundImage: `url(${pattern})`,}}></div>
          </div>

        </section>
      </div>

      <Footer />

    </main>
  )
}
