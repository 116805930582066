import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import reworkMetaImage from '../images/reworkMetaImage.jpeg'

export const Seo = ({ description, title }) => {
  const queryData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const metaTitle = title
    ? `${title} | ${queryData.site?.siteMetadata?.title}`
    : queryData.site?.siteMetadata?.title
  const metaDescription =
    description || queryData.site?.siteMetadata?.description

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="theme-color" content="#182C34" media="(prefers-color-scheme: light)"/>
      <meta name="theme-color" content="#182C34" media="(prefers-color-scheme: dark)"/>
      <meta property="og:title" content="Rework entertainment" />
      <meta property="og:url" content="https://reworkentertainment.com" />
      <meta property="og:image" content={reworkMetaImage} />
      <meta name="twitter:title" content={metaTitle}/>
      <meta name="twitter:description" content={metaDescription}/>
      <meta name="twitter:image" content={reworkMetaImage}/>
      <meta name="twitter:image:alt" content="Rework logo"/>
      <meta property='og:title' content={metaTitle}/>
      <meta property='og:image' content={reworkMetaImage}/>
      <meta property='og:description' content={metaDescription}/>
      <meta property='og:url' content="https://reworkentertainment.com"/>
    </Helmet>
  )
}
