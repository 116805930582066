import * as React from 'react'

export function Footer() {
  const date = new Date().getFullYear()
  return (
    <footer>
      Tous droits réservés Rework {date}
    </footer>
  )
}
